import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { TopBar } from "../../template/Topbar";
import Img from "gatsby-image";
import BuyLinks from "./BuyLinks";
import SocialLinks from "./SocialLinks";
import classnames from "classnames";
import style from "./Hero.module.scss";

const Hero: React.FC = () => {

    const data = useStaticQuery(graphql`
        query {
            introImage: file(relativePath: { eq: "HeroPic.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
            }
        }`);

    const [mounted, setMounted] = useState(false);

    return (
        <section className={style.root}>
            <TopBar className={style.headline} />
            <div className={style.layout}>
                <h1 className={style.title}>The Focused Writing App</h1>
                <div className={classnames(style.image, mounted ? style.imageFocused : "")}>
                    <Img 
                        onLoad={() => setMounted(true)}
                        fluid={{...data.introImage.childImageSharp.fluid }} 
                        alt="The picture shows Prime Draft on a laptop computer with the following text display: Prime Draft provides full screen, distraction free writing and a unique mode of writing that keeps you working with the writing process and not against it. Fully customizable, Prime Draft can track your writing targets, giving you that extra motivation and reward for achieving your writing goals."
                    />
                </div>
                <div className={style.text}>
                    <p>A fullscreen, distraction free writing app with a unique mode of writing that helps you write at your best.</p>
                    <BuyLinks location="LandingTop"/>
                </div>
            </div>
            <SocialLinks />
        </section>
    );

}

export default Hero;