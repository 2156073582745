import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import FacebookIcon from "../../assets/svg/facebook.inline.svg";
import TwitterIcon from "../../assets/svg/twitter.inline.svg";
import style from "./SocialLinks.module.scss";

const SocialLinks: React.FC = () => {

    return (
        <div className={style.socialLinks}>
            {false && <OutboundLink href="https://www.facebook.com/firstdraftwritingapp" target="_blank"><FacebookIcon /></OutboundLink> }
            {false && <OutboundLink href="https://twitter.com/FirstDraftApp1" target="_blank"><TwitterIcon /></OutboundLink> }
        </div>
    );

}

export default SocialLinks;