import React from "react";
import classnames from "classnames";
import useViewTracker from "../../hooks/ViewTracker";
import useObserver from "../../hooks/Observer";
import style from "./Section.module.scss";

type SectionProps = {
    viewName: string;
    title: React.ReactNode;
    text: React.ReactElement;
    sectionStyle: string;
    image?: React.ReactElement;
}

const Section: React.FC<SectionProps> = ({ viewName, title, text, sectionStyle, image }) => {

    const viewTrackerRef = useViewTracker(viewName);
    
    const obs = useObserver<HTMLDivElement>();
    const titleStyle = obs.viewed ? style.titleFocused : "";
    const textStyle = obs.viewed ? style.textFocused : "";
    const imageStyle = obs.viewed ? style.imageFocused : "";

    return (
        <section ref={viewTrackerRef} className={classnames(style.section, sectionStyle)}>
            <div ref={obs.ref} className={style.content}>
                <div className={style.textContainer}>
                    <h2 className={titleStyle}>{title}</h2>
                    <div className={classnames(style.text, textStyle)}>{text}</div>
                </div>
                {image && <div className={classnames(style.imageContainer, imageStyle)}>
                    {image}
                </div>}
            </div>
        </section>
    );

}

export default Section;