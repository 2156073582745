import React from "react";
import Section from "./Section";
import Img from "gatsby-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import style from "./Content.module.scss";
import BuyLinks from "./BuyLinks";

const query = graphql`
    query {
        writingScreen: file(relativePath: { eq: "WritingScreen.png" }) {
            childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            }
        }},
        settingScreen: file(relativePath: { eq: "SettingScreen.png" }) {
        childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            }
        }},
        themeScreen: file(relativePath: { eq: "ThemeScreen.png" }) {
            childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            }
        }},
        targetScreen: file(relativePath: { eq: "FileScreen.png" }) {
            childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            }
        }},
        createScreen: file(relativePath: { eq: "CreateScreen.png" }) {
            childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            }
        }},
    }`;

type SectionProps = {
    queryData: any;
}

/************** SECTION ONE *************/

const s1_text = <>
    <p>Whether you are writing a book, blog, or essay, getting started is always the hardest part. Lack of focus, constant distractions, and editing the same paragraph over and over again, can all take the fun out of writing.</p>
    <p>Created by Writers for Writers, Prime Draft is ideal for writing your first draft, rough draft, or just for getting your thoughts onto the page. Featuring a fullscreen display and a unique mode of writing, Prime Draft can help you reach new levels of creativity and focus when writing.</p>
</>;

const SectionOne: React.FC<SectionProps>= ({ queryData }) => 
    <Section
        viewName="Section_1"
        sectionStyle={style.sectionOne}
        title="Write a Prime Draft" 
        text={s1_text} 
        image={<Img 
            fluid={queryData.settingScreen.childImageSharp.fluid} 
            alt="The picture shows the settings screen of Prime Draft, distraction free writing software."  
        />} 
    />;

/************** SECTION TWO *************/

const s2_text = <>
    <p>Prime Draft puts all your focus into one thing: writing the next sentence. It might seem normal to edit our writing during the first draft. But each time we edit, we switch into a critical state of mind, losing momentum, inspiration and the mindset we need to write at our best. Prime Draft is all about getting your first draft done faster and better.</p>
    <p>In Prime Draft you only see the most recent lines of text, showing you only what you need to write the next sentence. Once that sentence is complete, it becomes uneditable. Prime Draft keeps you in the creative mindset and avoids the critical mindset, encouraging you to get into the writing flow and stay in it.</p>
</>;

const SectionTwo: React.FC<SectionProps> = ({ queryData }) =>
    <Section
        viewName="Section_2"
        sectionStyle={style.sectionTwo}
        title="Unique Writing Mode" 
        text={s2_text} 
        image={<Img 
            fluid={queryData.writingScreen.childImageSharp.fluid}
            alt="The picture shows Prime Draft writing software in fullscreen, distraction free writing mode. The following text is displayed: Fullscreen, distraction free writing and a unique mode of input that keeps you working with the writing process and not against it. Customize the look of your writing to reach new levels of creativity and focus. With automatic target tracking, save yourself time and get that extra motivation to reach your writing goals."
        />} 
    />;

/************** SECTION THREE *************/

const s3_text = <>
    <p>Research has shown that different colors can boost creativity, calmness and focus. Prime Draft allows you to not just select the color of your font, but also the color of the page itself. Using Prime Draft’s science-backed design and its numerous customization options, you can create a fullscreen writing environment that fully immerses you in your writing.</p>
</>;

const SectionThree: React.FC<SectionProps> = ({ queryData }) => 
    <Section
        viewName="Section_3"
        sectionStyle={style.sectionThree}
        title="Calm and Creative" 
        text={s3_text} 
        image={<Img 
            fluid={queryData.themeScreen.childImageSharp.fluid}
            alt="The picture shows the theme select screen of Prime Draft, distraction free writing software."
        />} 
    />;

/************** SECTION FOUR *************/

const s4_text = <>
    <p>Prime Draft allows you to set a goal for each file you create and automatically monitors your progress against that goal. You can target a total word count, writing time, or even set daily goals. Want to write 500 words a day for the next month? With an in-built calendar and to-do list, Prime Draft will always let you know how much progress you’ve made and what you need to do next. Prime Draft saves you time by doing the tracking for you.</p>
</>;

const SectionFour: React.FC<SectionProps> = ({ queryData }) => 
    <Section
        viewName="Section_4"    
        sectionStyle={style.sectionFour}    
        title="Effortless Tracking" 
        text={s4_text} 
        image={<Img 
            fluid={queryData.createScreen.childImageSharp.fluid} 
            alt="The picture shows the file creation screen of Prime Draft, and shows how word and time targets can be setup in the software."      
        />}
    />;

/************** SECTION FOUR *************/

const s5_text = <>
    <p>Prime Draft can give real accountability to your writing goals. Any target you set for a file can be enforced. This means you have to fully achieve your writing target before you can export your writing out of the app. Prime Draft can convert your writing targets into real commitments, helping motivate you to write until you reach your goal. Prime Draft can motivate you to write every day and build the writing habit.</p>
</>;

const SectionFive: React.FC<SectionProps> = ({ queryData }) => 
    <Section
        viewName="Section_5"      
        sectionStyle={style.sectionFive}    
        title="Writing Motivation" 
        text={s5_text} 
        image={<Img 
            fluid={queryData.targetScreen.childImageSharp.fluid} 
            alt="The picture shows the file viewing screen of Prime Draft, and shows how word and time targets can be tracked by the software."   
        />}
    />;

/************** FINAL SECTION *************/

const final_text = <>
    <p><b>No Lock In:</b> Prime Draft keeps it simple and exports all your writing in plain text format, leaving you free to use the software of your choice when you are ready to edit your draft.</p>
    <p><b>Multiple Themes:</b> With both a light and dark theme, Prime Draft will always match the look of your operating system.</p>
    <p className={style.preButtonPara}><b>One Time Purchase:</b> Level-up your writing for only $14.99! There are no hidden costs with Prime Draft. With an easy download via the Microsoft or Mac stores, you can start writing with Prime Draft in minutes!</p>
    <div className={style.buyButtons}>
        <BuyLinks location="LandingBottom" />
    </div>
    <p>Only $14.99 on Windows* and Mac</p>
    <p>Or learn more in the <Link className={style.faqLink} to="/blog/">Blog</Link> or <Link className={style.faqLink} to="/faq/">FAQ</Link></p>
    <p>*Free Trial on Windows</p>
</>

const SectionFinal: React.FC = () =>
    <Section
        viewName="Section_Final"      
        sectionStyle={style.lastSection}   
        title="And Much More..." 
        text={final_text}
    />

/************** ROOT COMPONENT *****************/

const Content: React.FC = () => {

    const queryData = useStaticQuery(query);

    return (
        <>
            <SectionOne queryData={queryData} />
            <SectionTwo  queryData={queryData} />
            <SectionThree queryData={queryData} />
            <SectionFour queryData={queryData} />
            <SectionFive queryData={queryData} />
            <SectionFinal />
        </>
    );
}

export default Content;
