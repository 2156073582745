import { useEffect, useRef } from "react";

const plixelLogView = (contentType: string) => {
    if(typeof window.fbq === "function") window.fbq("track", "ViewContent", { contentType });
}

const logView = (sectionName: string) => {

    if(window.gtag) {
        window.gtag("event", "LandingPage", { 
            event_category: "ContentView", 
            event_label: sectionName 
        });
    }
}

const observerOptions: IntersectionObserverInit = {
    root: null,
    rootMargin: "0px",
    threshold: .7,
};

const useViewTracker = (viewName: string) => {

    const targetRef = useRef<HTMLElement | null>(null);

    useEffect(() => {

        let hasViewed = false;

        const onView: IntersectionObserverCallback = (entries) => {
            
            entries.forEach(entry => {
                
                if(entry.isIntersecting && !hasViewed) {
                    logView(viewName);
                    if(viewName === "Section_Final") plixelLogView("Section_Final");
                    hasViewed = true;
                }
            });
        }

        const observer = new IntersectionObserver(onView, observerOptions);

        if(targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {

            observer.disconnect();
        }

    }, [viewName]);

    return targetRef;
}

export default useViewTracker;