import { useEffect, useRef, useState } from "react";

const observerOptions: IntersectionObserverInit = {
    root: null,
    rootMargin: "0px",
    threshold: .28,
};


const useObserver = <T extends Element>() => {

    const [viewed, setViewed] = useState(false);

    const ref = useRef<T>(null);

    useEffect(() => {

        if(viewed) return;

        const onView: IntersectionObserverCallback = (entries) => {
            
            entries.forEach(entry => {
                if(entry.isIntersecting) setViewed(true);
            });
        }

        const observer = new IntersectionObserver(onView, observerOptions);

        if(ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        }

    }, [viewed]);

    return {
        ref,
        viewed,
    }

}

export default useObserver;