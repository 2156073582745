import React from "react";
import { MacStoreLink, StoreClickLocation, WinStoreLink } from "../_util/StoreLinks";
import MacStoreImage from "../../assets/svg/macstore.inline.svg";
import winStoreImage from "../../assets/images/WinStore.png";
import style from "./BuyLinks.module.scss";

const BuyLinks: React.FC<{ location: StoreClickLocation }> = ({ location }) => {

    return (
        <>
            <MacStoreLink location={location} className={style.macLink} >
                <MacStoreImage className={style.macImage} />
            </MacStoreLink>
            <WinStoreLink location={location}>
                <img src={winStoreImage} alt='Buy Windows Store' className={style.winImage} />
            </WinStoreLink>
        </>
    );

}

export default BuyLinks;