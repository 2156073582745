import React from "react";
import { graphql } from "gatsby";
import SEO from "../../template/seo";
import Layout from "../../template/Layout";
import Hero from "./Hero";
import Content from "./Content";

export const pageQuery = graphql`
    query {
        socialImage: file(relativePath: { eq: "LandingSocial.png" }) {
          childImageSharp {
            resize(width: 2048) {
              src
              height
              width
          }}
        }
    }`;

type IndexPageProps = {
  data: any;
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => (
    <>
    <SEO 
        title="Focused Writing App | Prime Draft" 
        description="Prime Draft is a focused writing app with a unique mode of writing that helps you write at your best."
        image={data.socialImage.childImageSharp.resize}
        imageAlt="Prime Draft is distraction free writing program available on Windows 10 and macOS."
        structuredData='{
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Prime Draft",
            "operatingSystem": "Windows 10, macOS",
            "applicationCategory": "UtilitiesApplication",
            "applicationSubCategory": "Writing App",
            "featureList": "Fullscreen Writing, Distraction Free Writing, Focused Writing, Book Writing, Novel Writing, Essay Writing, Blog Writing",
            "offers": {
              "@type": "Offer",
              "price": "14.99",
              "priceCurrency": "USD"
            }
          }'
    />
    <Layout withBar={false}>
        <Hero />
        <Content />
    </Layout>
    </>
);

export default IndexPage;